interface WidgetConfiguration {
  headerMessage: string,
  theme: string,
  widgetId: string;
  endpointUrl: string;
  region: string;
  stage: string;
  cell: string;
  parentUrl: string;
  shouldShowMessageReceipts: string;
  staticStringsConfig?: string;
  displayNameConfig?: string;
  logoConfig?: string;
  isVoiceEnabled?: boolean,
  agentVideoOption?: string,
  customerVideoOption?: string
}

declare global {
  var AMAZON_CONNECT_WIDGET_CONFIGURATION: WidgetConfiguration;
  var connect : any;
}

// Need this for Typescript
export {};

// If its not in an Iframe, we want to return blank HTML
if(window.self === window.top) {
  document.body.innerHTML = '';
} else {
  let passthroughHeaders = {};
  window.self.addEventListener('message', function(event : MessageEvent) {
    let origin = AMAZON_CONNECT_WIDGET_CONFIGURATION.parentUrl;

    let chatProps = {
      name: '',
      supportedMessagingContentTypes: '',
    };

    if (typeof event.data === 'object' && event.data.call === 'amazon-connect-start-voice') {
      // When we receive a message from the initializer, send the VoiceInterface app the configuration
      let voiceProps = {
        name: '',
      };
      passthroughHeaders = event.data.data;
      const passthroughBody: Map<string, string> = event.data.body;
      voiceProps.name = passthroughBody?.get('name');

      if (passthroughBody.get('relatedChatContactId')) {
        voiceProps['relatedChatContactId'] = passthroughBody.get('relatedChatContactId');
      }

      if (passthroughBody.get('language')) {
        voiceProps['language'] = passthroughBody.get('language');
      }

      if (passthroughBody.get('contactAttributes')) {
        voiceProps['contactAttributes'] = JSON.parse(passthroughBody.get('contactAttributes'));
      }

      if (connect.VoiceInterface && typeof connect.VoiceInterface.init === 'function') {
        connect.VoiceInterface.init({
          containerId: 'root',
          widgetConfiguration: AMAZON_CONNECT_WIDGET_CONFIGURATION,
          headers: passthroughHeaders,
          ...voiceProps
        });
      }
    }

    if (typeof event.data === 'object' && event.data.call === 'amazon-connect-start-chat') {
      passthroughHeaders = event.data.data;
      const passthroughBody: Map<string, string> = event.data.body;
      //pass through body
      if (passthroughBody) {
        passthroughBody.forEach((value, key) => {
          if (value) {
            chatProps[key] = value;
          }
        });
      }

      const parseBrandingConfig = (config) => {
        if (!config) return {};
        let stringifiedJson = decodeURIComponent(config);
        return JSON.parse(stringifiedJson);
      }
  
      const decodeHeaderText = (headerText) => {
        try {
          return decodeURIComponent(headerText);
        } catch (Err) {
          console.error("Error while decoding using decodeURIComponent: trying unescape", Err);
          try {
            return unescape(headerText);
          } catch (e) {
            console.error("Error while decoding: unescape attribute was removed", e);
            return headerText;
          }
        }
      }
      if (connect.ChatInterface && typeof connect.ChatInterface.init === 'function') {
        let theme = JSON.parse(window.atob(AMAZON_CONNECT_WIDGET_CONFIGURATION.theme));
        let displayNameConfig = parseBrandingConfig(AMAZON_CONNECT_WIDGET_CONFIGURATION.displayNameConfig);
        let staticStringsConfig = parseBrandingConfig(AMAZON_CONNECT_WIDGET_CONFIGURATION.staticStringsConfig);
        let logoConfig = parseBrandingConfig(AMAZON_CONNECT_WIDGET_CONFIGURATION.logoConfig);
        let headerText = decodeHeaderText(AMAZON_CONNECT_WIDGET_CONFIGURATION.headerMessage);

        if (!!chatProps['customDisplayNames']) {
          const customDisplayNameObj = JSON.parse(chatProps['customDisplayNames']);
          headerText = customDisplayNameObj?.header?.headerMessage ? customDisplayNameObj.header.headerMessage : headerText;
          logoConfig.sourceUrl = customDisplayNameObj?.header?.logoUrl ? customDisplayNameObj.header.logoUrl : logoConfig?.sourceUrl;
          displayNameConfig.botMessage = customDisplayNameObj?.transcript?.botMessageDisplayName ? customDisplayNameObj.transcript.botMessageDisplayName : displayNameConfig?.botMessage
          displayNameConfig.systemMessage = customDisplayNameObj?.transcript?.systemMessageDisplayName ? customDisplayNameObj.transcript.systemMessageDisplayName : displayNameConfig?.systemMessage
          staticStringsConfig.endChatButton = customDisplayNameObj?.footer?.endChatButtonText ? customDisplayNameObj.footer.endChatButtonText : staticStringsConfig?.endChatButton;
          staticStringsConfig.textInputPlaceholder = customDisplayNameObj?.footer?.textInputPlaceholder ? customDisplayNameObj.footer.textInputPlaceholder : staticStringsConfig?.textInputPlaceholder;
        }
  
        connect.ChatInterface.init({
          containerId: 'root',
          themeConfig: {
            ...theme,
          },
          displayNameConfig,
          staticStringsConfig,
          logoConfig,
          headerConfig: {
            headerText,
          },
          widgetType: 'HostedChatWidget',
          shouldShowMessageReceipts: AMAZON_CONNECT_WIDGET_CONFIGURATION.shouldShowMessageReceipts,
          isVoiceEnabled: AMAZON_CONNECT_WIDGET_CONFIGURATION.isVoiceEnabled,
        });
      }

      connect.ChatInterface.initiateChat(
        {
          apiGatewayEndpoint: `${AMAZON_CONNECT_WIDGET_CONFIGURATION.endpointUrl}/widget/${AMAZON_CONNECT_WIDGET_CONFIGURATION.widgetId}/start`,
          headers: passthroughHeaders,
          region: AMAZON_CONNECT_WIDGET_CONFIGURATION.region,
          stage: AMAZON_CONNECT_WIDGET_CONFIGURATION.stage,
          cell: AMAZON_CONNECT_WIDGET_CONFIGURATION.cell,
          ...chatProps,
        },
        successHandler,
        failureHandler
      );

      if (connect.ChatEvents && typeof connect.ChatEvents.onVoiceEscalation === 'function') {
        connect.ChatEvents.onVoiceEscalation((chatContactId) => {
          const source = event.source as Window;
          source.postMessage({ call: 'amazon-connect-escalate-to-voice', chatContactId: chatContactId }, origin);
        });
      }

      connect.ChatEvents.onStartNewChat((callback) => {
        const source = event.source as Window;
        source.postMessage({ call: 'amazon-connect-start-new-chat' }, origin);
      });

      connect.ChatEvents.onChatEnded((callback) => {
        const source = event.source as Window;
        source.postMessage({ call: 'amazon-connect-end-chat' }, origin);
      });
      connect.ChatEvents.onChatLoading((callback) => {
        const source = event.source as Window;
        source.postMessage({ call: 'amazon-connect-loading-chat' }, origin);
      });
      connect.ChatEvents.onPushNotificationEligibleMessageReceived((callback) => {
        const source = event.source as Window;
        source.postMessage({ call: 'amazon-connect-push-notification-eligible-message-received' }, origin);
      });
      connect.ChatEvents.onAgentEndChat((callback) => {
        const source = event.source as Window;
        source.postMessage({ call: 'amazon-connect-agent-end-chat' }, origin);
      });
      function successHandler(session) {
        const source = event.source as Window;
        source.postMessage(
          { call: 'amazon-connect-chat-started', persistedChatSession: session.persistedChatSession, contactId: session.contactId },
          origin
        );
      }
      function failureHandler() {
        const source = event.source as Window;
        source.postMessage({ call: 'amazon-connect-chat-error' }, origin);
        console.error('Chat widget failed to initialize');
      }
    }
  }, false);
}
